<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/news' }">新闻资讯</el-breadcrumb-item>
      <el-breadcrumb-item>添加新闻资讯</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="formRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="归属类目">
          <el-radio-group v-model="pageForm.type">
            <el-radio :label="1">公司动态</el-radio>
            <el-radio :label="2">行业资讯</el-radio>
            <el-radio :label="3">系统公告</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="新闻资讯标题">
          <el-input v-model="pageForm.title" placeholder="请输入新闻资讯标题"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="pageForm.keywords" placeholder="请输入新闻资讯关键字"></el-input>
        </el-form-item>
        <el-form-item label="文章内容">
          <QuillBar v-model="pageForm.content" :contenttext="pageForm.content" @inputChange="itemChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item label="缩略图">
          <upload :url="pageForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改设置</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Add',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      pageForm: {
        sort: 0,
        type: 2,
        title: '',
        keywords: '',
        content: '',
        img: ''
      }
    }
  },
  methods: {
    async onSubmit () {
      if (!this.pageForm.title) {
        this.$message.error('请输入新闻资讯标题')
        return false
      }
      const { data: res } = await this.$http.post('/pc-news', this.pageForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.$router.push('/admin/apps/pc/news')
      } else {
        this.$message.error(res.msg)
      }
    },
    cancel () {
      this.$router.push('/admin/apps/pc/news')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    // 公司简介变化事件
    itemChange (val) {
      this.pageForm.content = val
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
/deep/.ql-container{
  min-height: 200px;
}
</style>
